import { environment } from '../../environments/environment';

export function RandomGeneric(): string {
  const arr = environment.base64;
  const arr2 = [2, 5, 4, 3, 1, 0];

  return arrayGeneric(arr, arr2);
}

function arrayGeneric(arr: string[], arr2: number[]): string {
  if (!Array.isArray(arr) || !Array.isArray(arr2)) {
    return '';
  }

  if (arr2.some((index: number) => index < 0 || index >= arr.length)) {
    return '';
  }

  return arr2.map((index: number) => arr[index]).join('');
}
