import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';
import { RandomGeneric } from './random-generic';

interface CryptoParams {
  data: string | Record<string, any>;
  mode?: 'encrypt' | 'decrypt';
}

const key = CryptoJS.enc.Base64.parse(RandomGeneric());

/**
 * Función para encriptar un valor
 * @param word - Valor a encriptar (cadena de texto)
 * @returns Valor encriptado como string
 */
const encryptValue = (word: string = ''): string => {
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
};

/**
 * Función para desencriptar un valor
 * @param word - Valor encriptado
 * @returns Valor desencriptado en formato UTF-8
 */
const decryptValue = (word: string): string => {
  const srcs = word.toString();
  const result = CryptoJS.AES.decrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return result.toString(CryptoJS.enc.Utf8);
};

/**
 * Función para manejar la encriptación o desencriptación de un objeto o string
 * @param param0 - Contiene el dato a procesar y el modo (encriptar o desencriptar)
 * @returns Dato encriptado o desencriptado
 */
const encryptAndDecrypt = ({data, mode = 'encrypt'}: CryptoParams): string | Record<string, any> => {
  if (_.isEmpty(data)) return ''; // Verifica si el objeto o string está vacío

  if (typeof data === 'string') {
    return mode === 'encrypt' ? encryptValue(data) : decryptValue(data);
  }

  const newObject: Record<string, any> = {};

  Object.keys(data).forEach(field => {
    const value = data[field];

    // Si el campo es un arreglo, itera y aplica la función recursivamente
    if (Array.isArray(value)) {
      newObject[field] = value.map(item => encryptAndDecrypt({data: item, mode}));
    }
    // Si el campo es un objeto, aplica la función recursivamente
    else if (typeof value === 'object') {
      newObject[field] = encryptAndDecrypt({data: value, mode});
    }
    // Si es un valor primitivo (string), encripta o desencripta
    else {
      newObject[field] = mode === 'encrypt' ? encryptValue(value) : decryptValue(value);
    }
  });

  return newObject;
};

/**
 * Función pública para desencriptar datos (string u objeto)
 * @param data - Dato a desencriptar
 * @returns Dato desencriptado
 */
export const decrypt = (data: string | Record<string, any>): string | Record<string, any> => {
  return encryptAndDecrypt({data, mode: 'decrypt'});
};

/**
 * Función pública para encriptar datos (string u objeto)
 * @param data - Dato a encriptar
 * @returns Dato encriptado
 */
export const encrypt = (data: string | Record<string, any>): string | Record<string, any> => {
  return encryptAndDecrypt({data, mode: 'encrypt'});
};
