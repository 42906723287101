// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.0.8',
  url_base: 'https://app-bppv.telmex.com',
  base64: ['dA==', 'VUJM', 'TEhw', 'aVBz', 'SWtP', 'QmpB'],
  apiUrl: 'https://app-bppv.telmex.com',
  urlDoument: 'https://app-bppv.telmex.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
