
<div class="container-modal">
  <p class="text-mode-dark">{{(countdownValue <= 0) ?
    'Su sesión ha finalizado.' :
    'La sesión finalizará en ' + countdownValue  + ' segundos, confirme que desea continuar en el sistema, o de lo contrario, su sesión finalizará.' }}</p>


  <button *ngIf="countdownValue <= 0; else options" mat-raised-button (click)="actionsClick()">Salir</button>

  <ng-template #options>
    <div class="w-100 d-flex align-items-center justify-content-center">
      <button class="mr-2" mat-raised-button (click)="actionsClick('extend')">Continuar</button>
      <button mat-raised-button (click)="actionsClick('logout')">Cerrar sesión</button>
    </div>
  </ng-template>
</div>
