import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgxImageCompressService} from 'ngx-image-compress';
interface DataDialogInterface {
  message: string;
}

@Component({
  selector: 'app-modal-capture-photo',
  templateUrl: './modal-capture-photo.component.html',
  styleUrls: ['./modal-capture-photo.component.css']
})
export class ModalCapturePhotoComponent implements OnInit, OnDestroy {
  cameraStream: MediaStream | null = null;
  takePicture = false;
  imageData: string;
  arrayPhotos: string[] = [];
  arrayPhotosCompress: string[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public  dataDialog: DataDialogInterface,
              public dialogRef: MatDialogRef<ModalCapturePhotoComponent>,
              private imageCompress: NgxImageCompressService) { }

  ngOnInit(): void {
    this.activateCamera();
  }

  activateCamera(): void {
    const constraints = { video: { facingMode: 'environment' } };
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          this.cameraStream = stream;
          // El permiso de la cámara se concedió y se obtuvo acceso
          const videoElement = document.getElementById('videoElement') as HTMLVideoElement;
          videoElement.srcObject = stream;
          videoElement.play();
        })
        .catch(error => {
          // El permiso de la cámara se denegó o hubo un error
          alert('Error al acceder a la cámara: ' + error);
        });
    } else {
      // La API MediaDevices no está disponible en este navegador
      alert('La API MediaDevices no está disponible');
    }
  }

  captureImageFromCamera(): void {
    // const constraints = { video: true } -> Code para activar camara frontal
    const constraints = { video: { facingMode: 'environment' } };
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        const videoElement = document.createElement('video');
        videoElement.srcObject = stream;
        videoElement.onloadedmetadata = () => {
          videoElement.play();

          const canvasElement = document.createElement('canvas');
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;

          const context = canvasElement.getContext('2d');
          context?.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
          // Imagen Capturada
          const imagenes  = canvasElement.toDataURL('image/jpeg');
          this.arrayPhotos.push(imagenes);

          // Detener la reproducción del video y liberar recursos
          videoElement.pause();
          videoElement.srcObject = null;
          stream.getTracks().forEach(track => track.stop());
          this.takePicture = true;
        };
      })
      .catch(error => {
        alert('Error al acceder a la cámara: ' + error);
      });
  }

  close(): void {
    if ( this.arrayPhotos.length === 0 ){
      this.dialogRef.close([]);
    } else {
      this.arrayPhotos.forEach(item => {
        const fileSizeInBytes = this.imageCompress.byteCount(item);
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        let calidad: number;
        if (fileSizeInMB <= 0.1) {
          calidad = 75;
        } else if (fileSizeInMB > 0.1 && fileSizeInMB <= 0.2) {
          calidad = 65;
        }
        else if (fileSizeInMB > 0.2 && fileSizeInMB <= 0.5) {
          calidad = 50;
        } else if (fileSizeInMB > 0.5 && fileSizeInMB <= 1) {
          calidad = 40;
        } else if (fileSizeInMB > 1 && fileSizeInMB <= 2) {
          calidad = 35;
        } else {
          calidad = 30;
        }
        this.imageCompress.compressFile(item, -2 , calidad, calidad).then(
          result => {
            this.arrayPhotosCompress.push(result);
          }
        );
      });
      const data = {
        hasData: true,
        data: this.arrayPhotosCompress
      };
      this.dialogRef.close(data);
    }
  }

  againPicture(): void {
    this.takePicture = false;
    this.activateCamera();
  }
  ngOnDestroy(): void {
    if (this.cameraStream) {
      this.cameraStream.getTracks().forEach(track => track.stop());
    }
  }

}
