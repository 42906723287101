import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalTimerComponent } from '../../shared/modal-timer/modal-timer.component';
import { MatDialog } from '@angular/material/dialog';
import { getDataStorage, saveDataStorage } from '../../utils/data-storage';
import { decrypt, encrypt } from '../../utils/cryptoJS';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { EXITO } from '../constants/response-code.constant';
import { TIPO_USUARO } from '../constants/general.constant';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {
  private idleTimeout: any;
  private countdownInterval: any;
  private countdownValue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private dialogOpen = false;


  constructor(private dialog: MatDialog,
              private router: Router,
              private authService: AuthService) {
    this.initTokenCheck();
  }

  initTokenCheck(): void {
    const respLogin = getDataStorage('tokenExpiracion');
    const expirationTime = respLogin?.replace(/['"]+/g, '');
    const expirationTimestamp = decrypt(expirationTime) as string;

    if (expirationTimestamp) {
      this.startTokenTimer(expirationTimestamp);
    }
  }

  startTokenTimer(expirationTime: string): void {
    // Obtener la zona horaria local del dispositivo
    const deviceTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convertir la hora de expiración (en America/Mexico_City) a la zona horaria del dispositivo
    const expirationDateLocal = moment.tz(expirationTime, 'America/Mexico_City').tz(deviceTimeZone).toDate();

    // imprime en consola la expirationDateLocal en un formato de hora en este formato 2025-03-21 12:11:57
    console.log('ExpirationTime:', moment(expirationDateLocal).format('YYYY-MM-DD HH:mm:ss'));

    if (isNaN(expirationDateLocal.getTime())) {
      console.error('El formato de fecha proporcionado no es válido.');
      return;
    }

    // Limpia cualquier temporizador previo
    if (this.idleTimeout) {
      clearInterval(this.idleTimeout);
    }

    this.idleTimeout = setInterval(() => {
      // Obtener la hora actual en la zona horaria del dispositivo
      const currentTimeLocal = new Date().getTime();

      // Calcular el tiempo restante en milisegundos
      const timeLeft = expirationDateLocal.getTime() - currentTimeLocal;

      if (timeLeft <= 2 * 60 * 1000) { // Menos de 2 minutos
        clearInterval(this.idleTimeout);

        // Abre el diálogo si no está abierto
        if (!this.dialogOpen) {
          this.dialogOpen = true;
          this.openCountdownDialog(Math.floor(timeLeft / 1000)); // segundos restantes
        }
      }
    }, 1000);
  }

  openCountdownDialog(seconds: number): void {
    const dialogRef = this.dialog.open(ModalTimerComponent, {
      width: '450px',
      height: '200px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.dialogOpen = false; // Restablece el estado del diálogo

      if (result === 'extend') {
        const data = getDataStorage('respLogin');

        const request = {
          tokenWS: data.tokenWS,
          empleado: data.empleado
        };

        this.authService.refreshToken(request).subscribe({
          next: (resp) => {
            if (resp.codigo === EXITO) {
              saveDataStorage('tokenExpiracion', resp.tokenExpiracion);
              this.startTokenTimer(decrypt(resp.tokenExpiracion) as string);
              this.resetCountdown();
            }
          }
        });
      } else if (result === 'logout') {
        this.sessionFinish();
      } else {
        this.sessionFinish();
      }
    });

    this.startCountdown(seconds);
  }

  startCountdown(seconds: number): void {
    this.countdownValue.next(seconds);

    this.countdownInterval = setInterval(() => {
      const currentValue = this.countdownValue.getValue();
      if (currentValue <= 0) {
        clearInterval(this.countdownInterval);
      } else {
        this.countdownValue.next(currentValue - 1);
      }
    }, 1000);
  }

  resetCountdown(): void {
    clearInterval(this.countdownInterval);
    clearInterval(this.idleTimeout);
    this.initTokenCheck();
    this.countdownValue.next(0);
  }

  getCountdownValue(): Observable<number> {
    return this.countdownValue.asObservable();
  }

  sessionFinish(): void {
    const tipoUsuario = getDataStorage('respLogin').tipoUsuario;
    //console.log('tipoUsuario', tipoUsuario);
    sessionStorage.clear();
    clearInterval(this.countdownInterval);
    clearInterval(this.idleTimeout);
    this.dialog.closeAll();
    if (tipoUsuario === TIPO_USUARO.USUARIO_PPV) {
      this.router.navigate(['/auth/login']);
    }else{
      this.router.navigate(['/auth/loginAdmin']);
    }

  }
}
