<div class="container-modal">
  <div *ngIf="typeModal === ModalType.SUCCESS" class="icon-rounded border-success">
    <mat-icon class="text-success">done</mat-icon>
  </div>

  <div *ngIf="typeModal === ModalType.ERROR" class="icon-rounded border-danger">
    <mat-icon class="text-danger">close</mat-icon>
  </div>

  <div *ngIf="typeModal === ModalType.WARNING" class="icon-rounded border-warning">
    <mat-icon class="text-warning">warning</mat-icon>

  </div>

  <div class="modal-body text-center text-muted">
    <p class="message text-muted texto-alertSize">{{ message }}</p>
  </div>

  <div class="modal-footer w-100">
    <button class="w-100 bg-info text-white" mat-raised-button color="accent" (click)="closeDialog()">{{ textButton
      }}</button>
  </div>
</div>
