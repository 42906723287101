import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appDecimalOnly]',
})
export class DecimalOnlyDirective {
  private regex: RegExp = new RegExp(/^(?!0\d)\d*\.?\d{0,2}$/); // Config para aceptar 2 decimales
  private specialKeys: string[] = [
    'Backspace',
    'Tab',
    'ArrowLeft',
    'ArrowRight',
    'Delete',
  ];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Permite teclas especiales como borrar, tabulador, flechas, etc.
    if (this.specialKeys.includes(event.key)) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    // Si el siguiente valor no cumple con el patrón, evitar la entrada
    if (next && !this.regex.test(next)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;

    // Validar el valor actual del input y corregir si es inválido
    if (!this.regex.test(input.value)) {
      let correctedValue = input.value.replace(/[^0-9.]/g, ''); // Elimina caracteres no permitidos

      // Corregir ceros al inicio no válidos
      if (correctedValue.startsWith('0') && correctedValue !== '0.' && correctedValue.length > 1) {
        correctedValue = correctedValue.replace(/^0+/, '');
      }

      this.renderer.setProperty(this.el.nativeElement, 'value', correctedValue);
    }
  }
}
