import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'ppv';
  deferredPrompt: any;

  constructor(
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    if (this.showIosInstallModal('iosInstallModalShown')) {
      this.openSnackBar('Añade esta app a tu pantalla de inicio desde el menú de Safari para una experiencia completa.', 'OK')
        .subscribe({
          next: () => {
            this.router.navigate(['/instalacion-ios']);
          }
        });
    }
  }

  ngOnInit(): void {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault(); // Evita el cuadro de diálogo automático
      this.deferredPrompt = event;

      // Muestra el snackbar
      this.openSnackBar('¿Quieres instalar esta aplicación?', 'Instalar').subscribe(
        () => {
          this.installApp();
        }
      );
    });
  }

  openSnackBar(message: string, action: string): Observable<any> {
    return this.snackBar.open(message, action).onAction();
  }

  installApp(): void {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt(); // Muestra el cuadro de diálogo de instalación
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('El usuario aceptó la instalación');
        } else {
          console.log('El usuario canceló la instalación');
        }
        this.deferredPrompt = null; // Limpia el prompt para que no vuelva a mostrarse
      });
    }
  }

  showIosInstallModal(localStorageKey: string): boolean {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    const isInStandaloneMode = () => {
      return 'standalone' in window.navigator && (window.navigator as any).standalone;
    };

    const iosInstallModalShown = JSON.parse(sessionStorage.getItem(localStorageKey) || 'false');
    const shouldShowModalResponse = isIos() && !isInStandaloneMode() && !iosInstallModalShown;

    if (shouldShowModalResponse) {
      sessionStorage.setItem(localStorageKey, 'true');
    }

    return shouldShowModalResponse;
  }

}
