export const MESSAGES = {
  ERROR_GENERICO: 'Por el momento el servicio no se encuentra disponible, por favor intente más tarde.',
  ERROR_USUARIO_CONTRASENA: 'Usuario o contraseña incorrectos.',
  ERROr_CONSULTA_EXPEDIENTE: 'Error al consultar la información, por favor intente más tarde.',
  ERROR_GUARDAR_PPV: 'Ocurrió un error al intentar guardar el usuario, por favor intente más tarde.',
  //
  ALERT_ARCHIVO_NO_PDF: 'El archivo seleccionado no es un PDF.',
  ALERT_ARCHIVO_NO_XML: 'El archivo seleccionado no es un XML.',
  ERROR_CARGA_MONTO: 'Ocurrió un error al registrar el monto, por favor intente más tarde.',
  ERROR_CARGA_DOCUMENTO: 'Ocurrió un error al registrar el documento, por favor intente más tarde.',

  MESSAGE_CONFIRM_LOGOUT: '¿Está seguro que desea cerrar sesión?',

  MESSAGE_ARCHIVO_GRNADE: 'El archivo seleccionado excede el tamaño permitido, por favor seleccione un archivo de menor tamaño.',
  ERROR_DOCUMENTO_NO_ENCONTRADO: 'El documento no se encuentra disponible.',
};
