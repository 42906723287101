<div class="w-100">

  <div class="w-100 h-80vh d-flex">
    <pdf-viewer *ngIf="isPdf"
                [src]="pdfContent"
                [render-text]="true"
                [original-size]="false">
    </pdf-viewer>

    <mat-dialog-content *ngIf="!isPdf" class="xml-dialog-content">
      <pre><code>{{ xmlContent }}</code></pre>
    </mat-dialog-content>
  </div>


  <div class="button-container w-100 d-flex justify-content-center align-items-center">
    <button class="w-45 bg-danger text-white mr-2" mat-flat-button (click)="closeDialog()">{{ dataDialog.textButton }}</button>
    <button *ngIf="isPdf" class="w-45 bg-danger text-white " mat-flat-button (click)="downloadPdf()"> Descargar PDF</button>
    <button *ngIf="!isPdf" class="w-45 bg-danger text-white " mat-flat-button (click)="downloadXml()"> Descargar XML</button>
  </div>
</div>
