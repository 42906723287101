<div class="w-100 vh-100">
  <div class="bkg-primary w-100">
    <div class="text-center">
      <div class="pt-3 pb-3"> <span class=" txt-white">PROCESO DE INSTALACIÓN</span> </div>
    </div>
  </div>

  <div class="w-90 col-md-4 m-auto">

    <div class="w-100 mt-3 text-center">
      <div class="" style="border-bottom: 1px solid #cacaca; padding-bottom: 30px">
        <h2 class="fs-title">PASO 1</h2>
        <h3 class="fs-subtitle">Haga clic en el botón <strong>Compartir</strong> que aparecerá en la barra del navegador, ubicada en la parte inferior de su dispositivo.</h3> <img src="assets/img/prcs-01.png" alt="paso uno" class="d-inline ">
      </div>
    </div>

    <div class="w-100 mt-3 text-center">
      <div style="border-bottom: 1px solid #cacaca; padding-bottom: 30px">
        <h2 class="fs-title">PASO 2</h2>
          <h3 class="fs-subtitle">El menú que se desplegará, seleccione la opción <strong>Agregar al inicio</strong>, para que la aplicación se descargue en su dispositivo.</h3> <img src="assets/img/prcs-02.png" alt="paso uno" class="d-inline ">
      </div>
    </div>

    <div class="w-100 mt-3 text-center">
      <div style="border-bottom: 1px solid #cacaca; padding-bottom: 30px">
        <h2 class="fs-title">PASO 3</h2>
          <h3 class="fs-subtitle">Posteriormente, haga clic en el botón <strong>Agregar</strong>, para que el aplicativo se descargue en su dispositivo.</h3> <img src="assets/img/prcs-03.png" alt="paso uno" class="d-inline ">
      </div>
    </div>

    <div class="w-100 mt-3 text-center">
      <div style="border-bottom: 1px solid #cacaca; padding-bottom: 30px">
        <h2 class="fs-title">PASO 4</h2>
          <h3 class="fs-subtitle">Unos segundos más tarde, tendrá la aplicación instalada en su dispositivo</h3> <img src="assets/img/prcs-04.png" alt="paso uno" class="d-inline ">
      </div>
    </div>

  </div>

</div>
