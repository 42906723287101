import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum ModalType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  CONFIRM = 'confirm',
}

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: [ './modal-dialog.component.css' ]
})
export class ModalDialogComponent implements OnInit {
  public readonly ModalType = ModalType;
  message: string;
  textButton: string;
  typeModal: string;

  constructor(@Inject(MAT_DIALOG_DATA) public dataDialog: any,
              private mdDialogRef: MatDialogRef<ModalDialogComponent>) {
    this.message = dataDialog.message;
    this.textButton = dataDialog.textButton || 'Aceptar';
    this.typeModal = dataDialog.modalType;
  }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.mdDialogRef.close();
  }
}
