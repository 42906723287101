export function saveDataStorage(key: string, data: any): void {
  sessionStorage.setItem(key, typeof data === 'string' ? data : JSON.stringify(data));

  // const dataString = JSON.stringify(data);
  // sessionStorage.setItem(key, dataString);
}

export function getDataStorage(key: string): any {
  const dataString = sessionStorage.getItem(key);
  try {
    if (dataString) {
      if (dataString.startsWith('{') || dataString.startsWith('[')) {
        return JSON.parse(dataString);
      }
      return dataString;
    }
    return null;
  } catch (error) {
    console.error('Error parsing JSON from sessionStorage for key:', key, error);
    return null;
  }
}

export function clearDataStorage(): void {
  sessionStorage.clear();
}
