import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ModalDialogComponent } from '../../shared/modal-dialog/modal-dialog.component';
import {ModalPreviewDocsComponent} from '../../shared/modal-preview-docs/modal-preview-docs.component';

export enum ModalType {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  CONFIRM = 'confirm',
  PREVIEW = 'preview',
}

interface DataDialogInterface {
  message: string;
  modalType: ModalType|string;
  textButton?: string;
  urlPdf?: string;
  urlXml?: string;
}


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private dialog: MatDialog) {
  }

  openModal(message: string, modal: string|ModalType = ModalType.SUCCESS, textButton: string = 'Aceptar',urlPdf:  string='', urlXml: string= '' ): Observable<any> {
    const data: DataDialogInterface = {
      message,
      modalType: modal,
      textButton,
      urlPdf,
      urlXml
    };

    let widht = '500px';
    let height = '300px';

    if (modal === ModalType.PREVIEW) {
      widht = '90%';
      height = '90%';
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    dialogConfig.width = widht;
    dialogConfig.height = height;

    let dialogRef;

    if (modal === ModalType.CONFIRM) {
      dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    } else if (modal === ModalType.PREVIEW) {
      dialogRef = this.dialog.open(ModalPreviewDocsComponent, dialogConfig);
    } else {
      dialogRef = this.dialog.open(ModalDialogComponent, dialogConfig);
    }

    return dialogRef.afterClosed().pipe(take(1), map((resp: any) => {
      return resp;
    }));
  }
}
