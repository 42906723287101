<video class="w-100" id="videoElement"></video>
<img [src]="imageData" width="100%" height="75%" alt="" *ngIf="takePicture">

<div class="d-flex justify-content-center">
  <div class="box-icon mr-5" (click)="captureImageFromCamera()"><i class="material-icons">photo_camera</i></div>
  <!--<div class="box-icon" (click)="againPicture()"><i class="material-icons">sync</i></div>-->
</div>

<div class="w-100 mt-2">
  <img *ngFor="let photo of arrayPhotos" [src]="photo" width="75px" height="75px" class="m-2" alt="">
</div>

<div class="d-flex justify-content-center mt-4">
  <button class="bg-danger text-white" mat-flat-button type="button" (click)="close()">Cerrar</button>
</div>
