import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../services/modal.service';
import { TokenExpirationService } from '../services/token-expiration.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private spinner: NgxSpinnerService,
              private modal: ModalService,
              private tokenExpirationService: TokenExpirationService) {
    console.log('Interceptor');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      setHeaders: {
        // Authorization: `Bearer token de prueba.`
        ContentType: 'application/json',
        Accept: 'application/json',
      }
    });

    this.spinner.show();

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error en la solicitud:', error.status);
        if (error.status === 401) {
          this.modal.openModal('La sesión ha expirado.', 'error').subscribe({
            next: () => this.tokenExpirationService.sessionFinish()
          });
        }
        return throwError(() => new Error(this.getErrorMessage(error)));
      }),
      finalize(() => this.spinner.hide())
    );
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    if (error.error instanceof ErrorEvent) {
      return `Error del cliente: ${ error.error.message }`;
    } else {
      return `Error del servidor: ${ error.status } ${ error.message }`;
    }
  }
}
