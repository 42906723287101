import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TokenExpirationService } from '../../core/services/token-expiration.service';

class DialogSessionTimerComponent {
}

@Component({
  selector: 'app-modal-timer',
  templateUrl: './modal-timer.component.html',
  styleUrls: [ './modal-timer.component.css' ]
})
export class ModalTimerComponent implements OnInit {
  countdownValue: number;

  constructor(private mdr: MatDialogRef<DialogSessionTimerComponent>,
              private tokenExpirationService: TokenExpirationService) {
  }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    this.tokenExpirationService.getCountdownValue().subscribe({
      next: (value: number) => {
        this.countdownValue = value;
      }
    });
  }

  actionsClick(action?: string): void {
    this.mdr.close(action);
  }

}
