<div class="container-modal">
  <div class="icon-rounded border-danger">
    <mat-icon>error</mat-icon>
  </div>

  <div class="w-100 text-center">
    <p class="text-muted texto-alertSize">{{message}}</p>
  </div>

  <div class="w-100 text-center">
    <button class="bg-info text-white mr-btn" mat-raised-button (click)="onConfirm()">Aceptar</button>
    <button class="bg-danger text-white" mat-raised-button (click)="onCancel()">Cancelar</button>
  </div>
</div>
