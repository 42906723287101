import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent {
    message: string;


  constructor(@Inject(MAT_DIALOG_DATA) public  dataDialog: any,
              private mdr: MatDialogRef<ConfirmDialogComponent>) {
    this.message = dataDialog.message;
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    // do something
    this.mdr.close(true);
  }

  onCancel(): void {
    // do something
    this.mdr.close(false);
  }
 }
