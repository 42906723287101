import {HttpClient} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from 'src/environments/environment';
import {MESSAGES} from '../../core/constants/messages.constant';
import {ModalService} from '../../core/services/modal.service';
import {PreviewDocumentService} from '../../core/services/preview-document.service';

@Component({
  selector: 'app-modal-preview-docs',
  templateUrl: './modal-preview-docs.component.html',
  styleUrls: ['./modal-preview-docs.component.css']
})
export class ModalPreviewDocsComponent implements OnInit {
  message: string;
  pdfContent: string;
  isPdf: boolean;
  xmlContent = '';

  constructor(@Inject(MAT_DIALOG_DATA) public dataDialog: any,
              private mdDialogRef: MatDialogRef<ModalPreviewDocsComponent>,
              private http: HttpClient,
              private modalService: ModalService,
              private previewDocumentService: PreviewDocumentService) {
    this.message = dataDialog.message;
  }

  ngOnInit(): void {
    console.log('Data Dialog', this.dataDialog);
    if (this.dataDialog.urlPdf && this.dataDialog.urlPdf.length > 0) {
      const urlPdf = this.dataDialog.urlPdf;
      this.loadPdf(urlPdf);
      this.isPdf = true;
    } else {
      this.leerXml(this.dataDialog.urlXml);
      this.isPdf = false;
    }

    console.log('Contenido pdf', this.pdfContent);
  }

  closeDialog(): void {
    this.mdDialogRef.close();
  }

  loadPdf(url: string): void {
    this.previewDocumentService.getDocument(url).subscribe({
      next: (blob) => {
        this.pdfContent = URL.createObjectURL(blob);
      },
      error: (error) => {
        this.modalService.openModal(MESSAGES.ERROR_DOCUMENTO_NO_ENCONTRADO, 'error').subscribe({
          next: () => {
            this.closeDialog();
          }
        });
      }
    });
  }

  leerXml(filePath: string): void {
    fetch(filePath)
      .then(response => {
        if (response.status === 404) {
          this.modalService.openModal(MESSAGES.ERROR_DOCUMENTO_NO_ENCONTRADO, 'error').subscribe({
            next: () => {
              this.closeDialog();
            }
          });
        }
        return response.text();
      })
      .then(data => {
        this.xmlContent = data;
      })
      .catch(error => console.error('Error al cargar el XML:', error));
  }

  downloadPdf(): void {
    const link = document.createElement('a');
    link.href = this.pdfContent;
    link.download = 'document.pdf';
    link.click();
  }

  downloadXml(): void {
    const blob = new Blob([this.xmlContent], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'document.xml';
    link.click();
  }

}
