import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {LoginRequestInterface, LoginResponseInterface} from '../interfaces/login.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url: string;

  constructor(private http: HttpClient) {
    this.url = environment.apiUrl;
  }

  login(request: LoginRequestInterface): Observable<LoginResponseInterface> {
    return this.http.post<any>(this.url+'/PPVoluntaria/sesion/loginAdmin', request);
  }

  refreshToken(request: any): Observable<any> {
    return this.http.post<any>(`${this.url}/PPVoluntaria/sesion/refrescar`, request);
  }
}
