import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictInputDirective } from '../utils/restrict-input.directive';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { MaterialModule } from '../utils/material.module';
import { ModalTimerComponent } from './modal-timer/modal-timer.component';
import { DecimalOnlyDirective } from '../utils/decymal-only.directive';
import { ModalPreviewDocsComponent } from './modal-preview-docs/modal-preview-docs.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalCapturePhotoComponent } from './modal-capture-photo/modal-capture-photo.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { IosInstructionsComponent } from './ios-instructions/ios-instructions.component';



@NgModule({
  declarations: [
    ConfirmDialogComponent,
    ModalDialogComponent,
    RestrictInputDirective,
    DecimalOnlyDirective,
    ModalTimerComponent,
    ModalPreviewDocsComponent,
    ModalCapturePhotoComponent,
    OrderByPipe,
    IosInstructionsComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    PdfViewerModule
  ],
  exports: [
    RestrictInputDirective,
    DecimalOnlyDirective,
    OrderByPipe
  ]
})
export class SharedModule { }
